// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-67-js": () => import("../src/pages/67.js" /* webpackChunkName: "component---src-pages-67-js" */),
  "component---src-pages-best-practices-js": () => import("../src/pages/best-practices.js" /* webpackChunkName: "component---src-pages-best-practices-js" */),
  "component---src-pages-host-js": () => import("../src/pages/host.js" /* webpackChunkName: "component---src-pages-host-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-speak-js": () => import("../src/pages/speak.js" /* webpackChunkName: "component---src-pages-speak-js" */)
}

